import React from "react"
import AnalyticsCard from "./AnalyticsCard"
import GridWrap from "./GridWrap"

const Analytics = ({ data }) => {
  const {
    primary: { analyticstitle },
    items,
  } = data

  return (
    <div className="analytics">
      <h2 className="analytics_title">{analyticstitle ? analyticstitle.map((title) => title.text).join(" ") : ""}</h2>
      <GridWrap noPaddings={true}>
        {items &&
          items.map((item, index) => (
            <AnalyticsCard
              key={item.cardtitle[0].text}
              index={index}
              image={item.cardimage}
              title={item.cardtitle.map((t) => t.text).join(" ")}
              figure={item.cardfigure.map((t) => t.text).join(" ")}
            />
          ))}
      </GridWrap>
    </div>
  )
}

export default Analytics
