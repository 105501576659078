import React, { useState, useEffect, useContext, useRef } from "react"
import Hamburger from "hamburger-react"
import { Link, withRouter } from "react-router-dom"

import client from "../prismic-configuration"
import { ReactComponent as Logo1 } from "../icons/logo-1.svg"
import { ReactComponent as Logo2 } from "../icons/logo-2.svg"
import { ReactComponent as Logo3 } from "../icons/logo-3.svg"
import { ReactComponent as LogoHorizontal1 } from "../icons/logo-horizontal-1.svg"
import { ReactComponent as LogoHorizontal2 } from "../icons/logo-horizontal-2.svg"
import { ReactComponent as LogoHorizontal3 } from "../icons/logo-horizontal-3.svg"
import Language from "./Language"
import { useCurrentPage } from "../hooks/use-current-page"
import { LanguageContext } from "../hooks/use-language"

const Nav = (props) => {
  const currentPagePath = useCurrentPage()
  const [nav, setNav] = useState([])
  const { language } = useContext(LanguageContext)
  const [navIsOpen, setNavOpen] = useState(false)
  const lastEl = useRef(null)
  const [logoVersion, setlogoVersion] = useState(1)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [navIsVisible, setVisible] = useState(true)
  const [reportsOpen, setReportsOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10
      setPrevScrollPos(currentScrollPos)
      setVisible(visible)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [prevScrollPos])

  const navClassName = navIsOpen ? "nav is-active" : navIsVisible ? "nav" : "nav hidden"

  const getRandomLogo = () => {
    const num = Math.floor(Math.random() * 3) + 1
    setlogoVersion(num)
  }

  useEffect(() => {
    getRandomLogo()
  }, [])

  let logo = ""
  if (logoVersion === 1) {
    logo = <Logo1 aria-hidden="true" />
  } else if (logoVersion === 2) {
    logo = <Logo2 aria-hidden="true" />
  } else {
    logo = <Logo3 aria-hidden="true" />
  }

  let logoHorizontal = ""
  if (logoVersion === 1) {
    logoHorizontal = <LogoHorizontal1 aria-hidden="true" />
  } else if (logoVersion === 2) {
    logoHorizontal = <LogoHorizontal2 aria-hidden="true" />
  } else {
    logoHorizontal = <LogoHorizontal3 aria-hidden="true" />
  }

  useEffect(() => {
    ;(async () => {
      await client
        .getSingle("main_navigation", { lang: language })
        .then((res) => {
          setNav(res.data.nav)
        })
        .catch((err) => console.log(err))
    })()
  }, [language])

  const handleNav = () => {
    if (document.body.classList.contains("nav-open")) {
      document.body.classList.remove("nav-open")
    } else {
      document.body.classList.add("nav-open")
    }
    const activeSubmenus = document.getElementsByClassName("is-submenu-active")
    for (let i = 0; i < activeSubmenus.length; i++) {
      activeSubmenus[i].classList.remove("is-submenu-active")
    }
    const activeSubmenuItems = document.getElementsByClassName("submenu-item__link")
    for (let i = 0; i < activeSubmenuItems.length; i++) {
      activeSubmenuItems[i].setAttribute("tabindex", "-1")
    }
  }

  const handleNavClose = () => {
    document.body.classList.remove("nav-open")
    setNavOpen(false)
    const activeSubmenus = document.getElementsByClassName("is-submenu-active")
    for (let i = 0; i < activeSubmenus.length; i++) {
      activeSubmenus[i].classList.remove("is-submenu-active")
    }
    const activeSubmenuItems = document.getElementsByClassName("submenu-item__link")
    for (let i = 0; i < activeSubmenuItems.length; i++) {
      activeSubmenuItems[i].setAttribute("tabindex", "-1")
    }
  }
  // Nav items
  const renderNavItems = () => {
    //exclude report, teemat, themes from the menu
    const navigationNoReports = nav
      .filter((el) => !el.primary?.label[0].text.toLowerCase().startsWith("report"))
      .filter(
        (el) =>
          el.primary?.label[0].text.toLowerCase() !== "teemat" && el.primary?.label[0].text.toLowerCase() !== "themes"
      )
    const navigationReports = nav.find((n) => n.items[0]?.sub_nav_link[0]?.text.includes("report"))

    return (
      <>
        {navigationNoReports.map((slice, i) => {
          const link = slice.primary?.link[0]?.text
          return (
            <li
              role="none"
              key={i}
              id={slice.items.length > 0 ? `has-submenu-${i}` : ""}
              className={navIsOpen ? "menu-item is-active" : "menu-item"}
            >
              <Link
                role="menuitem"
                className={link && link === currentPagePath ? "menu-item__link bold" : "menu-item__link"}
                onClick={handleNavClose}
                to={`${link}?lang=${language}`}
                tabIndex={navIsOpen ? "0" : "-1"}
              >
                {slice.primary?.label[0]?.text}
              </Link>
            </li>
          )
        })}
        {navigationReports?.items.length > 0 ? (
          <li
            role="none"
            id={navigationReports.length > 0 ? `has-submenu-${navigationReports.length + 1}` : ""}
            className={navIsOpen ? "menu-item is-active" : "menu-item"}
          >
            <p
              role="menuitem"
              className={"menu-item__link"}
              tabIndex={navIsOpen ? "0" : "-1"}
              onClick={() => setReportsOpen(!reportsOpen)}
            >
              {"Finnish Design Report"}
            </p>
          </li>
        ) : null}

        <div aria-label="submenu" className={reportsOpen ? "submenu open" : "submenu"}>
          {navigationReports?.items.map((slice, i) => {
            const link = slice.sub_nav_link[0]?.text
            return (
              <li role="none" key={i} className={navIsOpen ? "menu-item is-active" : "menu-item"}>
                <Link
                  role="menuitem"
                  className={
                    currentPagePath?.includes(`report-${i + 1}`) ? "menu-item__link bold sub" : "menu-item__link sub"
                  }
                  onClick={handleNavClose}
                  to={`${link}`}
                  tabIndex={navIsOpen ? "0" : "-1"}
                >
                  {slice.sub_nav_link_label[0]?.text}
                </Link>
              </li>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <nav className={navClassName}>
      {/* mobile logo */}
      <div className="logo-wrap mobile">
        <Link
          to={`?lang=${language}`}
          onClick={() => {
            handleNavClose()
            props.setFocus()
          }}
          aria-label={language === "fi" ? "Etusivu" : "Homepage"}
          onKeyDown={(e) => {
            if (e.key === "Tab" && e.shiftKey && navIsOpen) {
              lastEl.current.focus()
            }
          }}
          className="logo mobile"
        >
          {logoHorizontal}
        </Link>
      </div>
      {/* desktoplogo */}
      <div className="logo-wrap desktop">
        <Link
          className={"is-active"}
          to={`/?lang=${language}`}
          onClick={handleNavClose}
          aria-label={language === "fi" ? "Etusivu" : "Homepage"}
          onKeyDown={(e) => {
            if (e.key === "Tab" && e.shiftKey && navIsOpen) {
              lastEl.current.focus()
            }
          }}
        >
          {logo}
        </Link>
      </div>
      <div className={"menu-icon-wrap"}>
        <button>
          <Hamburger
            color="black"
            label={
              language === "fi"
                ? !navIsOpen
                  ? "Avaa valikko"
                  : "Sulje valikko"
                : !navIsOpen
                ? "Open menu"
                : "Close menu"
            }
            size={25}
            toggled={navIsOpen}
            toggle={setNavOpen}
            onToggle={handleNav}
          />
        </button>
        <div className={navIsOpen ? "language-switcher is-active" : "language-switcher"}>
          {["fi", "en"].map((l) => (
            <Language key={l} id={l} />
          ))}
        </div>
      </div>
      <div className={navIsOpen ? "menu-wrap" : ""} style={{ display: navIsOpen ? "flex" : "none" }}>
        <ul className="menu" role="menubar" aria-label={language === "fi" ? "Valikko" : "Menu"}>
          {renderNavItems()}
        </ul>
      </div>
    </nav>
  )
}

export default withRouter(Nav)
