import React, { useContext } from "react"
import ProgressiveImage from "react-progressive-image-loading"
import Skeleton from "@mui/material/Skeleton"
import { Helmet } from "react-helmet-async"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { ReactComponent as Logo1 } from "../icons/logo-1.svg"
import Hero from "./Hero"
import GridWrap from "./GridWrap"
import AboutCard from "./AboutCard"
import Subscribe from "./Subscribe"
import { useAboutPage } from "../hooks/use-about-page"
import { LanguageContext } from "../hooks/use-language"

const AboutPage = ({ match }) => {
  const { language } = useContext(LanguageContext)
  const { aboutDoc, heroDoc, subscribeDoc } = useAboutPage(match)

  const gridArray = aboutDoc.body.map((el) => {
    return {
      spans: el.primary.body1[0].spans ?? undefined,
      title: el.primary.title1[0].text,
      text: el.primary.body1.map((el) => el.text).join("\n\n"),
      link: el.primary.link ? "/" + el.primary.link.uid : "/",
      linkLabel: el.primary.link_label ? el.primary.link_label[0]?.text ?? "" : "",
    }
  })

  const renderLogo = () => {
    if (!heroDoc) {
      return <Skeleton variant="circular" sx={{ width: 80, height: 80 }} />
    }
    if ("url" in heroDoc.rightimage) {
      return (
        <ProgressiveImage
          preview={heroDoc.rightimage.url}
          src={heroDoc.rightimage.url}
          render={(src, style) => {
            return (
              <img
                src={src}
                style={{
                  ...style,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                alt={heroDoc.rightimage.alt ?? "ImageRight"}
              />
            )
          }}
        />
      )
    } else {
      return <Logo1 aria-hidden="true" />
    }
  }

  const renderHero = () => {
    let heroData = {
      to: "",
      title: "",
      text: "",
      buttonText: "",
    }
    if (heroDoc) {
      heroData = {
        to: heroDoc.title.map((t) => t.text).join("") ?? "",
        title: heroDoc.title.map((t) => t.text).join("") ?? "",
        text: heroDoc.text.map((t) => t.text).join("") ?? "",
        buttonText: heroDoc.buttonlabel.map((t) => t.text).join("") ?? "",
      }
    }

    return (
      <Hero
        to={heroData.to}
        title={heroData.title}
        text={heroData.text}
        buttonText={heroData.buttonText}
        bgcolor={"#afccfc"}
        highlightFirstSentence={false}
        logo={<div className="logo-wrap">{renderLogo()}</div>}
        loading={!heroDoc}
      />
    )
  }
  return (
    <>
      <Helmet>
        <title>{aboutDoc.title ? aboutDoc.title.map((t) => t.text).join("") : ""}</title>
      </Helmet>
      <TransitionGroup component={null}>
        <div className="home" aria-label={language === "fi" ? "Tietoa meiltä" : "About us page"}>
          <CSSTransition timeout={500} classNames="fade">
            <>
              {renderHero()}
              <div className="container-fluid" aria-label={language === "fi" ? "Sisältö" : "About page content"}>
                <GridWrap>
                  {gridArray.slice(0, 3).map((item, index) => (
                    <AboutCard
                      key={item.title}
                      index={index}
                      spans={item.spans}
                      title={item.title || ""}
                      text={item.text || item.contactUs}
                      link={
                        item.link.includes("undefined")
                          ? ""
                          : `${item.link}${language !== "fi" ? `?lang=${language}` : ""}`
                      }
                      linkLabel={item.linkLabel ?? ""}
                    />
                  ))}
                </GridWrap>
              </div>
              <div className="container-fluid" aria-label={language === "fi" ? "Sisältö" : "About page content"}>
                <GridWrap>
                  {gridArray.slice(3, 6).map((item, index) => (
                    <AboutCard
                      key={item.title}
                      index={index + 3}
                      spans={item.spans}
                      title={item.title || ""}
                      text={item.text || item.contactUs}
                      link={
                        item.link.includes("undefined")
                          ? ""
                          : `${item.link}${language !== "fi" ? `?lang=${language}` : ""}`
                      }
                      linkLabel={item.linkLabel ?? ""}
                    />
                  ))}
                </GridWrap>
              </div>
              {subscribeDoc ? (
                <Subscribe title={subscribeDoc.title[0].text} buttonText={subscribeDoc.button_label[0].text} />
              ) : null}
            </>
          </CSSTransition>
        </div>
      </TransitionGroup>
    </>
  )
}

export default AboutPage
