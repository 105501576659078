import React, { useState, useEffect, useContext, useCallback } from "react"
import client from "../prismic-configuration"
import Prismic from "prismic-javascript"
import { RichText, Date } from "prismic-reactjs"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Header from "./Header"
import { Link } from "react-router-dom"
import Subscribe from "./Subscribe"

import customLink from "../customLink"
import { ReactComponent as ParallaxArrow } from "../icons/arrow.svg"
import { Parallax } from "react-parallax"
import ProgressiveImage from "react-progressive-image-loading"
import NotFound from "./NotFound"
import { Helmet } from "react-helmet-async"
import HighlightSection from "./HighlightSection"
import { useCurrentPage } from "../hooks/use-current-page"
import { useHomePage } from "../hooks/use-home-page"
import { LanguageContext } from "../hooks/use-language"

const Page = (props) => {
  const { match } = props
  useCurrentPage(match.url)

  const { subscribeDoc } = useHomePage(match)
  const [doc, setDoc] = useState({
    title: "",
    uid: "",
    meta_description: "",
    tags: [],
    hero_image: {},
    hero_image_caption: "",
    slices: [],
  })
  const [pressReleases, setPressReleases] = useState([])
  const [redirect, setRedirect] = useState(false)
  const { language } = useContext(LanguageContext)

  const getDocument = useCallback(async () => {
    await client
      .getByUID("page", match.params.slug, { lang: language })
      .then((res) => {
        setDoc({
          title: res.data.title[0]?.text,
          uid: res.uid,
          meta_description: res.data.meta_description[0]?.text,
          tags: res.tags,
          hero_image: res.data.hero_image,
          hero_image_caption: res.data.hero_image_caption,
          slices: res.data.body,
        })
      })
      .catch((err) => {
        console.log(err)
        setRedirect(true)
      })
  }, [match, language])

  useEffect(() => {
    if (language) {
      getDocument(language)
    }
  }, [match, getDocument, language])

  const getPressReleases = useCallback(async () => {
    const res = await client.query(Prismic.Predicates.at("document.type", "press_release"), {
      lang: language,
      pageSize: 100,
      page: 1,
      orderings: "[document.first_publication_date desc]",
    })

    if (res) {
      setPressReleases(res.results)
    }
  }, [language])

  const docSlices = doc.slices.map((slice, i) => {
    if (slice.slice_type === "columns") {
      const columnContent = slice.items.map((item, i) => {
        // Nordic characters in slug are changed to english characters.
        // Change back to Nordic character.
        if (item.button.link_type === "Document" && item.button.slug === "kestavyys") item.button.slug = "kestävyys"
        if (item.button.link_type === "Document" && item.button.slug === "perinto") item.button.slug = "perintö"

        return (
          <div className="columns-grid__item" key={i}>
            <RichText render={item.content} serializeHyperlink={customLink} />

            {item.button.link_type === "Document" && (
              <div className="button-container">
                <Link to={`/stories-tag/${item.button.slug}/page/1?lang=${language}`}>
                  <button
                    className={`button ${item.button_style ? item.button_style : ""}`}
                    target="_blank"
                    rel="noreferrer"
                    key={i}
                  >
                    {item.button_label[0] && item.button_label[0].text}
                  </button>
                </Link>
              </div>
            )}
            {item.button.link_type === "Web" && (
              <div className="button-container">
                <a
                  className={`button ${item.button_style ? item.button_style : ""}`}
                  rel="noopener noreferrer"
                  href={`${item.button.url}`}
                  target="_blank"
                >
                  {item.button_label[0] && item.button_label[0].text}
                </a>
              </div>
            )}
            {item.button.type === "case" && (
              <div className="button-container">
                <Link className="button" to={`/cases/${item.button.uid}`} key={i}>
                  {item.button_label[0] && item.button_label[0].text}
                </Link>
              </div>
            )}
            {item.button.type === "news" && (
              <div className="button-container">
                <Link className="button" to={`/news/${item.button.uid}`} key={i}>
                  {item.button_label[0] && item.button_label[0].text}
                </Link>
              </div>
            )}
            {item.button.type === "page" && (
              <div className="button-container">
                <Link className="button" to={`/${item.button.uid}`} key={i}>
                  {item.button_label[0] && item.button_label[0].text}
                </Link>
              </div>
            )}
          </div>
        )
      })
      return (
        <div className={`columns ${slice.primary.background_color ? slice.primary.background_color : ""}`} key={i}>
          <div className="container">
            <div className="columns-grid">{columnContent}</div>
          </div>
        </div>
      )

      // Image
    } else if (slice.slice_type === "image") {
      return (
        <ProgressiveImage
          key={i}
          preview={slice.primary.image.preview}
          src={slice.primary.image.url}
          render={(src, style) => <img style={style} className="page-image" src={src} alt={slice.primary.image.alt} />}
        />
      )

      // Press releases
    } else if (slice.slice_type === "press_releases") {
      getPressReleases()
      const releases = pressReleases.map((item, i) => (
        <div key={i} className="press-releases-grid__item">
          <p className="updated">
            {Intl.DateTimeFormat("fi", {
              year: "numeric",
              month: "numeric",
              day: "2-digit",
            }).format(Date(item.first_publication_date))}
          </p>
          <a
            href={item.data.file && item.data.file.url}
            aria-label={
              language === "fi"
                ? `Avaa PDF-lehdistötiedote: ${item.data.title[0] && item.data.title[0].text}`
                : `Open PDF press release: ${item.data.title[0] && item.data.title[0].text}`
            }
          >
            <h4>{item.data.title[0] && item.data.title[0].text}</h4>
          </a>
          <div className="read-more">
            <p>
              <a
                href={item.data.file && item.data.file.url}
                aria-label={
                  language === "fi"
                    ? `Avaa PDF-lehdistötiedote: ${item.data.title[0] && item.data.title[0].text}`
                    : `Open PDF press release: ${item.data.title[0] && item.data.title[0].text}`
                }
              >
                {language === "fi" ? "Avaa PDF-tiedosto" : "Open PDF file"}
              </a>
            </p>
          </div>
        </div>
      ))
      return (
        <div className="press-releases" key={i}>
          <div className="container">
            <h2>{language === "fi" ? "Lehdistötiedotteet" : "Press releases"}</h2>
            <div className="press-releases-grid">{releases}</div>
          </div>
        </div>
      )
      // Arrow and content
    } else if (slice.slice_type === "arrow_and_content") {
      return (
        <div className="columns" key={i}>
          <div className="container">
            <div className="columns-grid">
              <div className="columns-grid__item">
                <div className="parallax-arrow">
                  <Parallax
                    strength={1000}
                    renderLayer={(percentage) => (
                      <ParallaxArrow style={{ transform: `translate(0, -${percentage * 100}px)` }} />
                    )}
                  />
                </div>
              </div>
              <div className="columns-grid__item parallax-item">
                <RichText render={slice.primary.content} serializeHyperlink={customLink} />
              </div>
            </div>
          </div>
        </div>
      )
      // Parallax element
    } else if (slice.slice_type === "parallax_element") {
      return (
        <div className="columns" key={i}>
          <div className="container">
            <div className="columns-grid">
              <div className={slice.primary.image_right ? "columns-grid__item order-2" : "columns-grid__item"}>
                <div className="parallax-arrow">
                  <Parallax
                    renderLayer={(percentage) => (
                      <ProgressiveImage
                        key={slice.primary.parallax_image.url}
                        preview={slice.primary.parallax_image.preview}
                        src={slice.primary.parallax_image.url}
                        render={(src, style) => (
                          <img
                            style={{ ...style, transform: `translate(0, -${percentage * 100}px)` }}
                            src={src}
                            alt={slice.primary.parallax_image.alt}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="columns-grid__item parallax-item">
                <RichText render={slice.primary.content} serializeHyperlink={customLink} />
              </div>
            </div>
          </div>
        </div>
      )
    } else if (slice.slice_type === "highlight_section") {
      return <HighlightSection slice={slice} key={i} i={i} />
    } else return null
  })
  return redirect ? (
    <NotFound />
  ) : (
    <>
      <Helmet>
        <title>{doc.title !== "" ? `${doc.title} - Finnish Design Info` : ""}</title>
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>
      <TransitionGroup component={null}>
        {doc.uid && (
          <CSSTransition key={doc.uid} timeout={500} classNames="fade">
            <div className="document__container">
              <Header text={doc.title} />
              <div className="document__body">
                <div className="document__image-wrap">
                  {doc.hero_image?.url && (
                    <ProgressiveImage
                      key={doc.hero_image?.url}
                      preview={doc.hero_image?.preview?.url}
                      src={doc.hero_image?.url}
                      render={(src, style) => (
                        <img className="document__image" src={src} style={style} alt={doc.hero_image.alt} />
                      )}
                    />
                  )}
                  {doc.hero_image_caption && doc.hero_image.url && (
                    <div className="document__image-caption">
                      <RichText render={doc.hero_image_caption} serializeHyperlink={customLink} />
                    </div>
                  )}
                </div>
                {docSlices}
              </div>
            </div>
          </CSSTransition>
        )}
        {subscribeDoc ? (
          <Subscribe title={subscribeDoc.title[0].text} buttonText={subscribeDoc.button_label[0].text} />
        ) : null}
      </TransitionGroup>
    </>
  )
}

export default Page
