import React, { useState, useEffect, useContext, useCallback } from "react"
import client from "../prismic-configuration"
import { RichText } from "prismic-reactjs"
import Related from "./Related"
import { Link } from "react-router-dom"
import customLink from "../customLink"
import Header from "./Header"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import { ReactComponent as FacebookShareIcon } from "../icons/facebook-share-icon.svg"
import { ReactComponent as LinkedinShareIcon } from "../icons/linkedin-share-icon.svg"
import { ReactComponent as TwitterShareIcon } from "../icons/twitter-share-icon.svg"
import { ReactComponent as WhatsappShareIcon } from "../icons/whatsapp-share-icon.svg"
import { ReactComponent as EmailShareIcon } from "../icons/email-share-icon.svg"
import ProgressiveImage from "react-progressive-image-loading"
import { Date as PrismicDate } from "prismic-reactjs"
import NotFound from "./NotFound"
import { Helmet } from "react-helmet-async"
import { LanguageContext } from "../hooks/use-language"

const SingleEvent = (props) => {
  const { match, history } = props
  const [doc, setDoc] = useState({
    title: "",
    meta_description: "",
    start_date: "",
    end_date: "",
    time: [],
    place: [],
    excerpt: [],
    uid: "",
    tags: [],
    hero_image: {},
    slices: [],
    lang: "",
    alternate_languages: [],
  })
  const [redirect, setRedirect] = useState(false)
  const { language } = useContext(LanguageContext)

  const getDocument = useCallback(async () => {
    const newDoc = await client.getByUID("event", match.params.slug, { lang: language })

    if (newDoc) {
      setDoc({
        title: newDoc.data.title[0]?.text,
        meta_description: newDoc.data.meta_description[0]?.text,
        start_date: newDoc.data.starting_date,
        end_date: newDoc.data.ending_date,
        time: newDoc.data.time,
        place: newDoc.data.place,
        excerpt: newDoc.data.excerpt,
        uid: newDoc.uid,
        tags: newDoc.tags,
        hero_image: newDoc.data.hero_image,
        slices: newDoc.data.body,
        lang: newDoc.lang,
        alternate_languages: newDoc.data.alternate_languages,
      })
    } else {
      setRedirect(true)
    }
  }, [language, match.params])

  useEffect(() => {
    // Get alternate language version on language change
    if (
      language &&
      doc?.alternate_languages?.length > 0 &&
      match.params.slug === doc.uid &&
      language.toLowerCase() !== doc.lang?.toLowerCase()
    ) {
      console.log("1")
      const langIndex = doc.alternate_languages.findIndex((x) => x.lang.toLowerCase() === language.toLowerCase())
      if (langIndex > -1) {
        console.log("2")
        history.push(`${doc.alternate_languages[langIndex].uid}?lang=${language}`)
      }
    }
    // First render or location change
    else if (language && match.params.slug !== doc.uid) {
      console.log("3")
      getDocument(match.params.slug)
    }
  }, [getDocument, match, language, doc, history])

  const docTags = doc.tags.map((tag, i) => {
    return (
      <li className="tag" key={i}>
        <Link to={`/events`}>{tag}</Link>
      </li>
    )
  })

  // Slices to render
  const docSlices =
    doc.slices &&
    doc.slices.map((slice, i) => {
      // Title
      if (slice.slice_type === "title") {
        return <RichText key={i} render={slice.primary.content} serializeHyperlink={customLink} />

        // Paragraph
      } else if (slice.slice_type === "paragraph") {
        return <RichText key={i} render={slice.primary.content} serializeHyperlink={customLink} />

        // Image with caption
      } else if (slice.slice_type === "image_with_caption") {
        return (
          <div key={i} className="image-with-caption">
            <ProgressiveImage
              preview={slice.primary.image.preview.url}
              src={slice.primary.image.url}
              render={(src, style) => <img src={src} style={style} alt={slice.primary.image.alt} />}
            />
            {slice.primary.caption ? <p className="caption">{slice.primary.caption[0].text}</p> : ""}
          </div>
        )

        // Button
      } else if (slice.slice_type === "button") {
        // Web or Media link
        if (slice.primary.link.link_type === "Web" || slice.primary.link.link_type === "Media") {
          return (
            <div key={i} className="button-container">
              <a className="button" href={slice.primary.link.url} target="_blank" rel="noopener noreferrer">
                {slice.primary.link_text[0].text}
              </a>
            </div>
          )
        }
        // Link to case document
        else if (slice.primary.link.type === "case") {
          return (
            <div key={i} className="button-container">
              <Link className="button" to={`/cases/${slice.primary.link.uid}`}>
                {slice.primary.link_text[0].text}
              </Link>
            </div>
          )
        }
        // Link to news document
        else if (slice.primary.link.type === "news") {
          return (
            <div key={i} className="button-container">
              <Link className="button" to={`/news/${slice.primary.link.uid}`}>
                {slice.primary.link_text[0].text}
              </Link>
            </div>
          )
        }
        // Link to page or else
        else {
          return (
            <div key={i} className="button-container">
              <Link className="button" to={`/${slice.primary.link.uid}`}>
                {slice.primary.link_text[0].text}
              </Link>
            </div>
          )
        }

        // Embed
      } else if (slice.slice_type === "embed") {
        const iframe = `<iframe src="${slice.primary.embed_url[0].text}" title="embedFrame" />`
        return <div key={i} className="embed-container" dangerouslySetInnerHTML={{ __html: iframe }} />

        // Return null by default
      } else {
        return null
      }
    })

  const startingTimestamp = PrismicDate(doc.start_date)
  const formattedStartingTimestamp = Intl.DateTimeFormat("fi", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(startingTimestamp)
  const endingTimestamp = PrismicDate(doc.end_date)
  const formattedEndingTimestamp = Intl.DateTimeFormat("fi", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(endingTimestamp)

  let eventDate = ""
  if (formattedStartingTimestamp === formattedEndingTimestamp) {
    eventDate = formattedStartingTimestamp
  } else if (
    formattedStartingTimestamp !== formattedEndingTimestamp &&
    formattedStartingTimestamp.substring(formattedStartingTimestamp.length - 4) ===
      formattedEndingTimestamp.substring(formattedEndingTimestamp.length - 4)
  ) {
    eventDate = `${formattedStartingTimestamp.substring(
      0,
      formattedStartingTimestamp.length - 4
    )}–${formattedEndingTimestamp}`
  } else {
    eventDate = `${formattedStartingTimestamp}-${formattedEndingTimestamp}`
  }

  return redirect ? (
    <NotFound />
  ) : (
    <>
      <Helmet>
        <title>{doc.title !== "" ? `${doc.title} - Finnish Design Info` : ""}</title>
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>
      <TransitionGroup component={null}>
        {doc.slices && doc.slices.length > 0 && (
          <CSSTransition key={doc.uid} timeout={500} classNames="fade">
            <div className="document__container">
              <Header text={doc.title} date={doc.date} tags={docTags} />
              <div className="document__body">
                <ProgressiveImage
                  preview={doc.hero_image.preview.url}
                  src={doc.hero_image.url}
                  render={(src, style) => (
                    <img
                      className="document__image document__image--article"
                      src={src}
                      style={style}
                      alt={doc.hero_image.alt}
                    />
                  )}
                />
                <div className="document__content event-content">
                  <main className="document__slices">{docSlices}</main>
                  <aside className="document__meta event-meta">
                    <div className="meta-wrap">
                      <ul className="tags">{docTags}</ul>
                      <p className="updated">{doc.date}</p>
                    </div>
                    <div className="icon-wrap">
                      <ion-icon name="calendar-outline" style={{ fontSize: "20px" }}></ion-icon>
                      <p>{eventDate}</p>
                    </div>
                    {doc.time[0] && doc.time[0].text !== "" && (
                      <div className="icon-wrap">
                        <ion-icon name="time-outline" style={{ fontSize: "20px" }}></ion-icon>
                        <p>{doc.time[0].text}</p>
                      </div>
                    )}
                    {doc.place[0] && doc.place[0].text !== "" && (
                      <div className="icon-wrap">
                        <ion-icon name="pin-outline" style={{ fontSize: "20px" }}></ion-icon>
                        <p>{doc.place[0].text}</p>
                      </div>
                    )}
                    {doc.excerpt[0] && doc.excerpt[0].text !== "" && (
                      <div className="excerpt">
                        <RichText render={doc.excerpt} serializeHyperlink={customLink} />
                      </div>
                    )}
                    <p className="share-text">{language === "fi" ? "Jaa tapahtuma" : "Share event"}</p>
                    <div className="share-buttons">
                      <FacebookShareButton url={window.location.href}>
                        <FacebookShareIcon />
                      </FacebookShareButton>
                      <LinkedinShareButton url={window.location.href}>
                        <LinkedinShareIcon />
                      </LinkedinShareButton>
                      <TwitterShareButton url={window.location.href}>
                        <TwitterShareIcon />
                      </TwitterShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <WhatsappShareIcon />
                      </WhatsappShareButton>
                      <EmailShareButton url={window.location.href}>
                        <EmailShareIcon />
                      </EmailShareButton>
                    </div>
                  </aside>
                </div>
              </div>
              {doc.tags && doc.uid ? <Related type="news" tags={doc.tags} excludeUID={doc.uid} /> : ""}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

export default SingleEvent
