import React, { useContext } from "react"
import { Parallax } from "react-parallax"
import { Link } from "react-router-dom"
import Skeleton from "@mui/material/Skeleton"

import { getFirstSentence, getTextColorForBackground } from "../helpers/helper-functions"
import HeroLeft from "./Animations/HeroLeft"
import HeroMobileRight from "./Animations/HeroMobileRight"
import HeroMobileLeft from "./Animations/HeroMobileLeft"
import HeroRight from "./Animations/HeroRight"
import { LanguageContext } from "../hooks/use-language"

const Hero = ({
  to,
  bgcolor = "#006969",
  logo = undefined,
  highlightFirstSentence = false,
  title,
  text,
  buttonText,
  animation = false,
  loading,
}) => {
  const { language } = useContext(LanguageContext)
  let firstSentence = ""
  let newText = ""

  // If first sentence needs to be highlighted with bold
  if (highlightFirstSentence) {
    firstSentence = getFirstSentence(text).first
    newText = getFirstSentence(text).rest
  } else {
    newText = text ? text : ""
  }

  //Get a text color depending on the lumncance of the backround
  const textColor = getTextColorForBackground(bgcolor)

  //split sentences if \n\n are present in text
  const sentences = newText.split("\n\n")
  const splitedText = sentences.map((sentence, index) => (
    <React.Fragment key={index}>
      {sentence}
      <br />
      <br />
    </React.Fragment>
  ))

  if (typeof logo !== "undefined") {
    return (
      <div className="hero with-logo" style={{ backgroundColor: bgcolor }} aria-label="hero">
        <div className="hero_left" aria-label="text_section">
          {loading ? (
            <Skeleton variant="text" sx={{ width: "100%", height: 80 }} />
          ) : (
            <h1 style={{ color: textColor, paddingTop: 20, paddingBottom: 20, textAlign: "left" }}>{title}</h1>
          )}

          {loading ? (
            <Skeleton variant="text" sx={{ width: "100%", height: 300 }} />
          ) : (
            <>
              {highlightFirstSentence ? <p className="hero_text bold">{firstSentence}</p> : null}
              <p className="hero_text with-logo" style={{ color: textColor }}>
                {newText.includes("\n\n") ? splitedText : newText}
              </p>
            </>
          )}
          {buttonText ? (
            <Link to={to} className="button" style={{ color: textColor, borderColor: textColor }}>
              {buttonText}
            </Link>
          ) : null}
        </div>
        {}

        {logo ? logo : null}
      </div>
    )
  }
  return (
    <div className="hero" style={{ backgroundColor: bgcolor }} aria-label={language === "fi" ? "Hero" : "Hero"}>
      {animation ? (
        <>
          <div className="hero_animation_container" aria-hidden="true">
            <Parallax
              renderLayer={(percentage) => (
                <div
                  aria-hidden="true"
                  style={{ transform: `translate(0, -${percentage * 100}px)` }}
                  className="hero_animation_parallax"
                >
                  <div className="hero_animation left" aria-hidden="true">
                    <HeroLeft />
                  </div>
                  <div className="hero_animation right" aria-hidden="true">
                    <HeroRight />
                  </div>
                </div>
              )}
            />
          </div>
          <div className="hero_animation_container_mobile" aria-hidden="true">
            <Parallax
              renderLayer={(percentage) => (
                <div
                  aria-hidden="true"
                  style={{ transform: `translate(0, -${percentage * 100}px)` }}
                  className="hero_animation_parallax"
                >
                  <div
                    className="hero_animation left"
                    aria-hidden="true"
                    style={{ transform: `rotate(-${percentage * 100 + 240}deg` }}
                  >
                    <HeroMobileLeft />
                  </div>
                  <div className="hero_animation right" aria-hidden="true">
                    <HeroMobileRight />
                  </div>
                </div>
              )}
            />
          </div>
        </>
      ) : null}
      {loading ? (
        <Skeleton variant="text" sx={{ maxWidth: 600, width: "100%", height: 80 }} />
      ) : (
        <h1 className="hero_title about">{title}</h1>
      )}

      {loading ? (
        <>
          <Skeleton variant="text" sx={{ maxWidth: 500, height: 40, width: "100%" }} />
          <Skeleton variant="text" sx={{ maxWidth: 500, height: 40, width: "100%" }} />
          <Skeleton variant="text" sx={{ maxWidth: 500, height: 40, width: "100%" }} />
          <Skeleton variant="text" sx={{ maxWidth: 500, height: 40, width: "100%" }} />
        </>
      ) : (
        <>
          {highlightFirstSentence ? <p className="hero_text bold">{firstSentence}</p> : null}
          <p className="hero_text" style={{ color: textColor }}>
            {newText}
          </p>
        </>
      )}
      {!loading ? (
        buttonText ? (
          <Link to={to} className="button">
            {buttonText}
          </Link>
        ) : null
      ) : (
        <Skeleton variant="button" sx={{ width: 250, height: 60, marginTop: 2 }} />
      )}
    </div>
  )
}

export default Hero
