import React, { useContext, useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import TagManager from "react-gtm-module"
import { Switch, Route } from "react-router-dom"
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"

import "./sass/styles.scss"
import Home from "./components/Home"
import Page from "./components/Page"
import Nav from "./components/Nav"
import ArchiveNews from "./components/ArchiveNews"
import SingleNews from "./components/SingleNews"
import SingleEvent from "./components/SingleEvent"
import Footer from "./components/Footer"
import ArchiveTheme from "./components/ArchiveTheme"
import ArchiveEvent from "./components/ArchiveEvent"
import TitleAnnouncer from "./components/TitleAnnouncer"
import AboutPage from "./components/AboutPage"
import CurrentPageContextProvider from "./components/CurrentPageContext"
import { HelmetProvider } from "react-helmet-async"
import { LanguageContext } from "./hooks/use-language"

const App = () => {
  const { language } = useContext(LanguageContext)
  const [cookieConsentAccept, setCookieConsentAccept] = useState(false)
  const focusEl = useRef(null)

  useEffect(() => {
    if (getCookieConsentValue("CookieConsent") === "true") {
      const tagManagerArgs = {
        gtmId: "GTM-WDMCRP9",
      }
      TagManager.initialize(tagManagerArgs)
      window.dataLayer.push({
        event: "pageview",
      })
    }
  }, [cookieConsentAccept])

  const setFocus = () => {
    focusEl.current.focus()
  }

  return (
    <HelmetProvider>
      <CurrentPageContextProvider>
        <TitleAnnouncer setFocus={setFocus} />
        <div className="app">
          <div tabIndex="-1" ref={focusEl} />
          <a href="#content" className="straight-to-content">
            {language === "fi" ? "Suoraan sisältöön" : "Straight to content"}
          </a>
          <Nav setFocus={setFocus} />
          <div id="content" className="document" tabIndex="-1">
            {/* @DEPRICATED substituted with helper-fucntion handleScroll() */}
            {/* <ScrollToTop /> */}
            <CookieConsent
              // debug prop is necessary to show the cookie consent banner in development
              debug={process.env.NODE_ENV === "development"}
              location="bottom"
              buttonText={language === "fi" ? "Hyväksyn" : "Accept"}
              enableDeclineButton
              declineButtonText={language === "fi" ? "Vain välttämättömät evästeet" : "Only necessary cookies"}
              ariaAcceptLabel={language === "fi" ? "Hyväksyn" : "Accept"}
              ariaDeclineLabel={language === "fi" ? "Vain välttämättömät evästeet" : "Only necessary cookies"}
              cookieName="CookieConsent"
              expires={30}
              disableStyles
              onAccept={() => setCookieConsentAccept(true)}
            >
              <p>
                {language === "fi"
                  ? "Tämä verkkosivu käyttää evästeitä sivuston käyttökokemuksen parantamiseen. Klikkaamalla 'Hyväksyn' osoitat hyväksyväsi evästeiden käytön tai voit myös kieltää sen. "
                  : "This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish. "}
                <Link
                  aria-label={
                    language === "fi" ? "Lue lisää evästekäytänteistämme" : "Read more about our Cookie Policy"
                  }
                  to={`/privacy-policy?${language}`}
                >
                  {language === "fi" ? "Lue lisää" : "Read more"}
                </Link>
              </p>
            </CookieConsent>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/home" exact component={Home} />
              <Route exact path="/about" component={AboutPage} />

              <Route path="/stories" exact component={ArchiveNews} />
              <Route path="/stories/page/:number" component={ArchiveNews} />
              <Route path="/stories/:slug" component={SingleNews} />
              <Route path="/stories-tag" exact component={ArchiveNews} />
              <Route path="/stories-tag/:tag/page/:number" component={ArchiveNews} />
              <Route path="/stories-tag/:tag" component={ArchiveNews} />

              <Route path="/themes/:slug" exact component={ArchiveTheme} />
              <Route path="/themes/:slug/page/:number" component={ArchiveTheme} />

              <Route path="/events" exact component={ArchiveEvent} />
              <Route path="/events/:slug" component={SingleEvent} />

              <Route path="/:slug" component={Page} />
            </Switch>
          </div>
          <Footer />
        </div>
      </CurrentPageContextProvider>
    </HelmetProvider>
  )
}

export default App
