import React, { useContext } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import Subscribe from "./Subscribe"

import ArchiveItem from "./ArchiveItem"
import MaintenanceMode from "./MaintenanceMode"
import NewsLetterForm from "./NewsLetterForm"
import Hero from "./Hero"
import Analytics from "./Analytics"
import GridWrap from "./GridWrap"
import Highlight from "./Highlight"
import { useHomePage } from "../hooks/use-home-page"
import { LanguageContext } from "../hooks/use-language"

const Home = ({ match }) => {
  const { language } = useContext(LanguageContext)
  const {
    doc,
    contentCards,
    highlightDoc,
    heroDoc,
    newsletterActive,
    handleNewsLetter,
    // socialLinks,
    analytics,
    highlight,
    subscribeDoc,
  } = useHomePage(match)

  const renderContentCards = () =>
    contentCards.slice(0, 6).map((card) => {
      return <ArchiveItem key={card.uid} item={card} />
    })

  const renderHero = () => {
    let newHeroData = {
      title: "",
      buttonText: "",
      text: "",
      to: "",
      animation: false,
      highlightFirstSentence: false,
    }
    if (heroDoc) {
      const { title, buttonlabel, text, animationshown } = heroDoc
      // const to = !buttonlink?.slug || buttonlink.slug === "-" ? buttonlink.type : buttonlink.slug
      // hardcoding to avoid errors 404
      const to = "about"

      newHeroData = {
        title: title ? title.map((t) => t.text).join(" ") : "",
        buttonText: buttonlabel ? buttonlabel[0].text : "",
        text: text ? text.map((t) => t.text).join(" ") : "",
        to: language === "fi" ? `${to}?lang=fi` : `${to}?lang=en`,
        animation: animationshown,
        highlightFirstSentence: heroDoc.highlightfirstsentence,
      }
    }
    return (
      <Hero
        to={newHeroData.to}
        title={newHeroData.title}
        text={newHeroData.text}
        buttonText={newHeroData.buttonText}
        highlightFirstSentence={newHeroData.highlightFirstSentence}
        animation={newHeroData.animation}
        loading={!heroDoc}
      />
    )
  }

  const renderAnalytics = () => {
    if (analytics) {
      return <Analytics data={analytics} />
    }
  }

  const renderHighlight = () => {
    if (highlight && highlightDoc) {
      const image = {
        alt: highlightDoc.featured_image.alt ?? "Highlight Image",
        url: highlightDoc.featured_image.url ?? "",
        preview: highlightDoc.featured_image.preview.url ?? "",
      }
      const data = {
        title: highlight.primary.title1 ? highlight.primary.title1.map((item) => item.text).join(" ") : "",
        text: highlight.primary.text ? highlight.primary.text.map((item) => item.text).join("") : "",
        type: highlight.primary.readmore.type ?? "",
        uid: highlight.primary.readmore.uid ?? "",
        image: image,
      }
      return (
        <Highlight
          bgcolor="#A0C8C8"
          title={data.title}
          text={data.text}
          image={data.image}
          uid={data.uid}
          type={data.type}
        />
      )
    } else return null
  }

  return (
    <>
      <Helmet>
        <title>{doc.title !== "" ? `${doc.title} - Finnish Design Info` : ""}</title>
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>
      {doc.uid && (
        <TransitionGroup>
          <div className="archive">
            <CSSTransition timeout={500} classNames="fade">
              <>
                {renderHero()}
                {renderAnalytics()}
                {renderHighlight()}
                <div className="events_separator" />
                <h2 className="events_title">{language === "fi" ? "Ajankohtaista" : "Latest news"}</h2>
                {doc.slices.length > 0 && (
                  <div className="container-fluid">
                    <GridWrap>{renderContentCards()}</GridWrap>
                  </div>
                )}
                <Link to={`/stories?lang=${language}`} className="button">
                  {language === "fi" ? "Lue lisää" : "View all"}
                </Link>
                {subscribeDoc ? (
                  <Subscribe title={subscribeDoc.title[0].text} buttonText={subscribeDoc.button_label[0].text} />
                ) : null}
                <NewsLetterForm newsletterActive={newsletterActive} handleNewsLetter={handleNewsLetter} />
              </>
            </CSSTransition>
          </div>
        </TransitionGroup>
      )}
      {doc.maintenance_mode === "true" && <MaintenanceMode isActive={doc.maintenance_mode} />}
    </>
  )
}

export default Home
