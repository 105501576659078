import ProgressiveImage from "react-progressive-image-loading"
import React, { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../hooks/use-language"

const AnalyticsCard = ({ title, figure, image, index }) => {
  const { language } = useContext(LanguageContext)
  const middleCard = index === 1
  const [showComponent, setShowComponent] = useState(false)

  // This effect is to avoid the error "Can't perform a React state update on an unmounted component"
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowComponent(true)
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [])

  return showComponent ? (
    <div
      className={middleCard ? "analytics_card middle" : "analytics_card"}
      aria-label={language === "fi" ? "Tilasto, osa " + index + 1 : "Statistics Card " + index + 1}
      role="contentinfo"
    >
      <div aria-hidden="true">
        {title ? <p className="analytics_text">{title}</p> : null}
        {image ? (
          <ProgressiveImage
            preview={image.url}
            src={image.url}
            render={(src, style) => (
              <img
                src={src}
                style={{
                  ...style,
                  width: "100%",
                  objectFit: "contain",
                  marginLeft: figure.length > 2 ? figure.length * -10 : 0,
                  marginBottom: middleCard ? "8vh" : 0,
                }}
                alt={image.alt}
                className="analytics_image"
              />
            )}
          />
        ) : null}
        {figure ? <h1 className={middleCard ? "analytics_figure large" : "analytics_figure"}>{figure}</h1> : null}
      </div>
    </div>
  ) : null
}

export default AnalyticsCard
